const validateBusinessEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidFormat = re.test(String(email).toLowerCase());
    
    if (!isValidFormat) {
        return false;
    }
    
    const invalidDomains = ['gmail.com', 'yahoo.com', 'mailinator.com'];
    const domain = email.split('@')[1];
    
    return !invalidDomains.includes(domain);
};

export default validateBusinessEmail;

