import { Link } from 'gatsby';
import React from 'react'
import './app-button.css'


function AppButton({ variant, text, linkTo, style, hasArrow, target, ...restProps }: { variant: string | 'variant-white' | 'variant-teal', text: string, linkTo?: string, style?: {}, hasArrow?: boolean, onClick?: () => void, target?: '_blank' | '_self' | '_parent' | '_top' }) {
  if (linkTo) {
    return (<Link to={linkTo} target={target ?? '_self'}>
      <button className={variant} {...restProps} style={style}>{text}{hasArrow && <span className='has-arrow'></span>}</button>
    </Link>)
  }

  return (
    <button className={variant} {...restProps} style={style}>{text}{hasArrow && <span className='has-arrow'></span>}</button>
  )
}

export default AppButton;