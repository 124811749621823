import React from 'react';
import { Helmet } from 'react-helmet';

type structuredDataType = any;

type HelmetProps = {
    title: string;
    canonicalLink?: string;
    metaDescription: string;
    openGraphImage: string;
    noindex?: boolean;
    structuredData?: structuredDataType;
};


const HtmlHead: React.FC<HelmetProps> = ({ title, canonicalLink, metaDescription, openGraphImage, structuredData, noindex= false }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>{title}</title>
            {canonicalLink && <link rel="canonical" href={canonicalLink} />}
            <meta name="description" content={metaDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={openGraphImage} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={metaDescription} />
            <meta property="twitter:image" content={openGraphImage} />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet" />
            {structuredData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />}
            {noindex && <meta name="robots" content="noindex"></meta>}
        </Helmet>
    );
};

export default HtmlHead;